let key = {};

let toasterOption = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

let Envname = "demo";
if (Envname == "demo") {
  const API_URL = "https://dopamineicoapi.wealwindemo.com"; //DEMO
  //const API_URL = "https://api.demo.biz"; //Live
  key = {
    baseUrl: `${API_URL}`,
    toasterOption: toasterOption,
    USDTcontract: "0xeC4c33BBE67a8B54Cc9b7a3603c47fA3d2C52367",
    admin_address: "0x83c6bF7F45641b44a974FC68170A71A0e9C8109E",  
    DefiqIDO: "0x56A2cd3B29546892764a98E26c340625aBc9e3cC",
    DefiQToken: "0x6B3317E4a08F7BFc6D1C0b25c1907a315Ba161A4",
    NetworkId: 97,
    decimals: 1e18,
    HashLink: "https://testnet.bscscan.com/tx/",
    symbol: "BNB",
    // rpcUrl: "https://bsc-dataseed1.binance.org",
    rpcUrl: "https://bsc-testnet.publicnode.com",
    ScanURL: "https://testnet.bscscan.com/address",
  };
} else {
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:2053`,
    toasterOption: toasterOption,
    USDTcontract: "0xeC4c33BBE67a8B54Cc9b7a3603c47fA3d2C52367",
    admin_address: "0x83c6bF7F45641b44a974FC68170A71A0e9C8109E",
    DefiqIDO: "0x56A2cd3B29546892764a98E26c340625aBc9e3cC",
    DefiQToken: "0x6B3317E4a08F7BFc6D1C0b25c1907a315Ba161A4",
    NetworkId: 97,
    decimals: 1e18,
    HashLink: "https://testnet.bscscan.com/tx/",
    symbol: "BNB",
    rpcUrl: "https://bsc-testnet.publicnode.com",
    ScanURL: "https://testnet.bscscan.com/address",
  };
}

export default key;
