import Web3 from "web3";
import config from "../lib/config";
import store from '.././store';

export async function connection() {

    var currentProvider = store.getState()
    console.log('currentProviderdddddddddddddddddddddddd: ', currentProvider);

    var connect = {
        web3: "",
        address: "",
        network: 0,
        provider: "",
        isChange: "false"
    };

    var provider = (currentProvider && currentProvider.walletConnection &&
        currentProvider.walletConnection.provider
        && currentProvider.walletConnection.provider !== "") ? currentProvider.walletConnection.provider : "";

    var isConnected = "no"
    var WEB3_CONNECT_CACHED_PROVIDER = localStorage.getItem("wagmi.wallet")
    if (WEB3_CONNECT_CACHED_PROVIDER) {
        console.log('WEB3_CONNECT_CACHED_PROVIDER: ', WEB3_CONNECT_CACHED_PROVIDER);
        var connnector = JSON.parse(WEB3_CONNECT_CACHED_PROVIDER)
        if (connnector === "metaMask" || connnector === "walletConnect") {
            isConnected = "yes";
        }
    }

    if (isConnected && provider && provider !== "" && currentProvider && currentProvider.walletConnection &&
        currentProvider.walletConnection.provider) {
        provider = currentProvider.walletConnection.provider
        console.log('provideddddddddddddddddddddr: ', provider);

        //var provider = await web3Modal.connect();
        var web3 = new Web3(provider);
        if (typeof web3 !== "undefined") {

            var network = await web3.eth.net.getId();
            var result = await web3.eth.getAccounts();

            var currAddr = result[0];
            console.log('currAdsssssssssssssdr: ', currAddr);
            var bnbBalance = await web3.eth.getBalance(currAddr);
            bnbBalance = bnbBalance / 10 ** 18


            if (currAddr === undefined) currAddr = "";
            console.log(network === config.NetworkId, 'rrrrrrrrrrrrrrrrrrrr')
            if (network === config.NetworkId) {
                connect.network = network;
                connect.web3 = web3;
                connect.address = currAddr;
                connect.provider = provider;
                connect.isChange = "false";
                connect.connect = "yes";
                connect.bnbbal = bnbBalance;
            }
            else if (network !== config.NetworkId) {
                connect.web3 = "";
                connect.address = "";
                connect.network = 0;
                connect.provider = "";
                connect.isChange = "true";
                connect.connect = "no";

            }
            else {
                return connect;
            }

        }
    }
    return connect;
}