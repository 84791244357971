import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionRoute from "./Route/ConditionRoute";
import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const UserHistory = lazy(() => import("./History/UserHistory.js"));
const TransactionHist = lazy(() => import("./History/TransactionHist"));
const ListFAQ = lazy(() => import("./FAQ/ListFAQ.js"));
const AddFAQ = lazy(() => import("./FAQ/AddFAQ.js"));
const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));
const Login = lazy(() => import("./user-pages/Login"));
const Setting = lazy(() => import("./Pages/Setting/setting"));

export default function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <ConditionRoute
          path="/dashboard"
          component={Dashboard}
          type={"private"}
        />

        <ConditionRoute
          path="/faq/list-faq"
          component={ListFAQ}
          type={"private"}
        />

        <ConditionRoute
          path="/faq/add-faq"
          component={AddFAQ}
          type={"private"}
        />

        <ConditionRoute
          path="/setting/admin-setting"
          component={Setting}
          type={"private"}
        />

        <ConditionRoute
          path="/history/user-history"
          component={UserHistory}
          type={"private"}
        />

        <ConditionRoute
          path="/history/transaction-hist"
          component={TransactionHist}
          type={"private"}
        />

        <ConditionRoute
          path="/change-password"
          component={ChangePassword}
          type={"private"}
        />
        <ConditionRoute
          path="/user-pages/login-1"
          component={Login}
          type={"auth"}
        />
        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}
